/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-06-12 15:20:09
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-10-16 16:57:21
 * @FilePath: /settlement-frontend/src/core/directive/function/finacialReport.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { BadgeStyle } from "../type/common";
import { OCPSSSReceivablesStatus } from "../type/finacialReport";

export const getOCPSSSReceivablesStatus = (status: number): string => {
  let statusClass = "";
  switch (status) {
    case OCPSSSReceivablesStatus.UnSettled:
      statusClass = BadgeStyle.LightDanger;
      break;
    case OCPSSSReceivablesStatus.Settled:
      statusClass = BadgeStyle.LightWarning;
      break;
    case OCPSSSReceivablesStatus.Cleared:
      statusClass = BadgeStyle.LightSuccess;
      break;
    default:
      statusClass = BadgeStyle.LightSuccess;
      break;
  }
  return "text-uppercase " + statusClass;
};
