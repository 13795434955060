import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "subtable_template text-start fw-normal" }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { class: "d-flex px-3" }
const _hoisted_4 = { class: "flex-1 d-flex flex-column gap-4" }
const _hoisted_5 = { class: "d-flex flex-column" }
const _hoisted_6 = { class: "text-gray-400" }
const _hoisted_7 = { class: "fw-bold" }
const _hoisted_8 = { class: "d-flex flex-column" }
const _hoisted_9 = { class: "text-gray-400" }
const _hoisted_10 = { class: "fw-bold" }
const _hoisted_11 = { class: "flex-1 d-flex flex-column gap-4" }
const _hoisted_12 = { class: "d-flex flex-column" }
const _hoisted_13 = { class: "text-gray-400" }
const _hoisted_14 = { class: "fw-bold" }
const _hoisted_15 = { class: "d-flex flex-column" }
const _hoisted_16 = { class: "text-gray-400" }
const _hoisted_17 = { class: "fw-bold" }
const _hoisted_18 = { class: "flex-1 d-flex flex-column gap-4" }
const _hoisted_19 = { class: "d-flex flex-column" }
const _hoisted_20 = { class: "text-gray-400" }
const _hoisted_21 = { class: "fw-bold" }
const _hoisted_22 = { class: "d-flex flex-column" }
const _hoisted_23 = { class: "text-gray-400" }
const _hoisted_24 = { class: "fw-bold" }
const _hoisted_25 = { class: "flex-1 d-flex flex-column gap-4" }
const _hoisted_26 = { class: "d-flex flex-column" }
const _hoisted_27 = { class: "text-gray-400" }
const _hoisted_28 = { class: "fw-bold" }
const _hoisted_29 = { class: "d-flex flex-column" }
const _hoisted_30 = { class: "text-gray-400" }
const _hoisted_31 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_currency = _resolveComponent("common-currency")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", {
      colspan: _ctx.tableColspans,
      class: "text-start px-20 py-9"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("finacialReport.debtorNo")) + ":", 1),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.itemData.debtor_external_number
                  ? _ctx.itemData.debtor_external_number
                  : "--"), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("finacialReport.invoicableNetAmount")) + ":", 1),
            _createElementVNode("span", _hoisted_10, [
              _createVNode(_component_common_currency, {
                currency: _ctx.itemData.currency,
                amount: _ctx.itemData.total_net_amount
              }, null, 8, ["currency", "amount"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("finacialReport.odOrderAmount")) + ":", 1),
            _createElementVNode("span", _hoisted_14, [
              _createVNode(_component_common_currency, {
                currency: _ctx.itemData.currency,
                amount: _ctx.itemData.order_amount
              }, null, 8, ["currency", "amount"])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("finacialReport.invoicableGrossAmount")) + ":", 1),
            _createElementVNode("span", _hoisted_17, [
              _createVNode(_component_common_currency, {
                currency: _ctx.itemData.currency,
                amount: _ctx.itemData.total_gross_amount
              }, null, 8, ["currency", "amount"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t("finacialReport.serviceFeeRate")) + ":", 1),
            _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.itemData.fee_rate + "%"), 1)
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t("finacialReport.osDate")) + ":", 1),
            _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.itemData.order_date ? _ctx.formatDate(_ctx.itemData.order_date) : "--"), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t("finacialReport.odReceivables")) + ":", 1),
            _createElementVNode("span", _hoisted_28, [
              _createVNode(_component_common_currency, {
                currency: _ctx.itemData.currency,
                amount: _ctx.itemData.payable_amount
              }, null, 8, ["currency", "amount"])
            ])
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t("finacialReport.paidDate")) + ":", 1),
            _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.itemData.paid_time ? _ctx.formatDate(_ctx.itemData.paid_time) : "--"), 1)
          ])
        ])
      ])
    ], 8, _hoisted_2)
  ]))
}