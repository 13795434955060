
import { computed, defineComponent, ref } from "vue";
import { formatDate } from "@/core/directive/function/common";
import { OCPSDSRebatesTable } from "@/core/directive/interface/finacialReport";

export default defineComponent({
  name: "transaction-report-finacial-report-ocps-ss-sales-receivables-sub-table",
  props: {
    itemData: {
      type: Object as () => OCPSDSRebatesTable,
      required: true,
    },
    tableColspan: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props) {
    const loading = ref<boolean>(false);
    const base = ref("");
    const order = ref("");
    const isRequest = ref<boolean>(false);

    const formData = ref({
      items: [] as any[],
    });

    const options = ref({});

    const tableColspans = computed(() => {
      return props.tableColspan + 2;
    });

    return {
      formatDate,
      props,
      loading,
      base,
      order,
      options,
      isRequest,
      formData,
      tableColspans,
    };
  },
});
