
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { ApiBase, ApiDebtor } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";
import { OCPSDSRebatesTableFilter } from "@/core/directive/interface/finacialReport";
import { DebtorOption } from "@/core/directive/interface/debtor";

export default defineComponent({
  name: "transaction-report-finacial-report-ocps-ds-rebates-dropdown",
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<OCPSDSRebatesTableFilter>({
      status: "",
      debtor_entity_id: "",
      creditor_entity_id: "",
      relation_order_number: "",
      invoice_date_start: "",
      invoice_date_end: "",
      order_date_start: "",
      order_date_end: "",
    });

    const options = ref({
      statusOptions: [] as TaggingItem[],
      entityLoading: false,
      entityOptions: [] as DebtorOption[],
    });

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["settlement_invoice_status"],
      });
      if (data.code == 0) {
        options.value.statusOptions = data.data.settlement_invoice_status.items;
      }
    };

    const getEntityData = async (value) => {
      options.value.entityLoading = true;
      const { data } = await ApiDebtor.searchDebtor({
        value: value,
      });
      options.value.entityLoading = false;
      if (data.code == 0) {
        options.value.entityOptions = data.data;
      }
    };

    const debounceEntitySearch = _.debounce(getEntityData, 1000);

    const searchEntityItems = (query: string) => {
      debounceEntitySearch(query);
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getTaggingData();
    });

    return {
      t,
      formData,
      formRef,
      options,
      searchEntityItems,
      submit,
      handleReset,
    };
  },
});
