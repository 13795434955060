/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-06-12 15:22:01
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-06-13 14:18:00
 * @FilePath: /settlement-frontend/src/core/directive/type/finacialReport.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

export enum OCPSSSReceivablesStatus {
  UnSettled = 10,
  Settled = 20,
  Cleared = 30,
}
